import { IonContent, IonPage } from "@ionic/react";
import "./TypeformRegisterPage.scss";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const TypeformRegisterPage: React.FC = () => {

    const { companyCode } = useParams<{ companyCode: string }>();
    const TYPEFORM_FORM_ID='SadIMlf1';
    const iframeSrc = `https://journey-live.typeform.com/to/${TYPEFORM_FORM_ID}#company=${companyCode}`;
    
    useEffect(() => {
        // Remove a specific meta tag by its name
        const appleMetaTag = document.querySelector("meta[name='apple-itunes-app']");

        if (appleMetaTag) {
            document.head.removeChild(appleMetaTag);
        }

        // Add a cleanup if you re-add these meta tags later
        return () => {
            // Re-add or handle any further cleanup
            if (appleMetaTag) document.head.appendChild(appleMetaTag);
        };
    }, []);
    
    return (
        <IonPage className="typeform-register-page">
            <IonContent className="typeform-register-content">
                <iframe src={iframeSrc} className="frame" />
            </IonContent>
        </IonPage>
    );
}
