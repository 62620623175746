import { IonContent, IonItem, IonInput, IonButton, isPlatform, IonText, IonSpinner } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import "./LoginComponent.scss";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { useAuth0 } from '@auth0/auth0-react';
import Logo from "../../assets/images/logo-journey.svg";
import AnalyticsService from "../../misc/AnalyticsService";
import ReactCodeInput from "react-code-input";
import { useLocation } from "react-router-dom";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';

interface LoginComponentProps {
    isComponentVisible: boolean;
    email?: string;
}

export const LoginComponent: React.FC<LoginComponentProps> = (props: LoginComponentProps) => {
    const [email, setEmail] = useState<string | null>(props.email ?? null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [password, setPassword] = useState<string | null>(null);
    const [code, setCode] = useState<string>("");
    const [isCodeValid, setIsCodeValid] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const { buildAuthorizeUrl } = useAuth0();
    const isIos = isPlatform('ios');
    const { isMobileApp } = useContext(ApplicationContext);
    const inputElement = useRef<HTMLIonInputElement>(null);
    const { t } = useTranslation();
    let location: any = {};
    const redirectUri = isPlatform('android') ? "journeylive://com.journeylive.android" : isPlatform('ios') ? 'journeylive://com.journeylive.ios' : window.location.origin;

    location = useLocation();

    useEffect(() => {
        if (props.isComponentVisible) {
            if (props.email) {
                // email was provided beforehand
                loginWithSSOOrMagicLink();
            } else {
                setEmail(null);
                setIsLoading(false);
                setError(null);
                setTimeout(async () => {
                    if (!inputElement.current) return;
                    const input = await inputElement.current.getInputElement();
                    input?.focus()
                }, 500);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isComponentVisible]);

    const loginWithSSOOrMagicLink = () => {
        if (email && validateEmail(email)) {
            (async () => {
                setIsLoading(true);
                setError("");
                try {
                    let response = await JourneyApiClient.getRealmDiscovery(email);
                    if (response.realm === "sso") {
                        setShowPassword(false);
                        trackLoginSuccessfulEvent();
                        //sso login
                        const url = await buildAuthorizeUrl({ login_hint: email });
                        // Redirect using Capacitor's Browser plugin
                        await Browser.open({
                            url,
                            windowName: "_self",
                            presentationStyle: "popover",
                        });
                        setIsLoading(false);
                    }
                    else if (response.realm === "workos-sso") {
                        setShowPassword(false);
                        trackLoginSuccessfulEvent();
                        //sso login
                        const url = await JourneyApiClient.getWorkOsRedirectUrl(email.split('@')[1], redirectUri, null);
                        // Redirect using Capacitor's Browser plugin
                        await Browser.open({
                            url,
                            windowName: "_self",
                            presentationStyle: "popover",
                        });
                        setIsLoading(false);
                    }
                    else if (response.realm === "magic-link") {
                        //magic link login
                        setShowPassword(false);
                        JourneyApiClient.sendLoginEmail(email)
                            .then(() => {
                                trackLoginSuccessfulEvent();
                                setIsLoading(false);
                                setIsSubmitted(true);
                            })
                            .catch((error) => {
                                trackLoginFailEvent();
                                setIsLoading(false);
                                setError("Could not send login instructions.");
                            });
                    } else if (response.realm === "code") {
                        //code login
                        setShowPassword(false);
                        JourneyApiClient.sendCodeLoginEmail(email)
                            .then(() => {
                                trackLoginSuccessfulEvent();
                                setIsLoading(false);
                                setIsSubmitted(true);
                            })
                            .catch((error) => {
                                trackLoginFailEvent();
                                setIsLoading(false);
                                setError("Could not send login instructions.");
                            });
                    } else if (response.realm === 'password') {
                        setShowPassword(true);
                        setIsLoading(false);
                    } else {
                        trackLoginFailEvent();
                        setError("Login not supported yet.");
                        setIsLoading(false);
                    }
                } catch (e: any) {
                    let message = e?.message ?? t("This email address is not in our records.");
                    if (e?.errorCode === 404) {
                        message = t(`Please try another email address you may have, whether work or personal email address. If you have any questions, please email us at support@journey.live.`);
                    }
                    trackLoginFailEvent();
                    // add sentry here
                    setError(message);
                    setIsLoading(false);
                }
            })();
        } else {
            setError("Enter a valid email address.");
        }
    };

    async function handleLogin() {
        if (email && isSubmitted) {
            await verifyCodeLogin();
        }
        else if (email && !showPassword) {
            loginWithSSOOrMagicLink();
        } else if (email && password) {
            loginWithPassword();
        }
    }

    async function verifyCodeLogin() {
        setIsLoading(true);
        setError("");
        if (!email) {
            //this should not happen as code is being entered because an email was sent
            await submitAgain();
            return;
        }
        try {
            // submit email and code to get auth token
            const response = await JourneyApiClient.verifyCodeLogin(email, Number(code));

            if (response.status!==200) {
                //token not in response
                //this should not happen, as the endpoint will return a token or throw an exception that is managed below
                setIsCodeValid(false);
                setError("Code is invalid");
                setIsLoading(false);
            } else {
                //token was returned in the response
                //JourneyApiClient.setAuthCredentials("x");
                window.location.replace(JourneyApiClient.getRedirectUrl() || "/");
                //console.log('all good');
            }
        } catch (exception: any) {
            setIsLoading(false);
            setIsCodeValid(false);
            setError(exception.message);
            trackLoginFailEvent();
        }
    }

    async function loginWithPassword() {
        if (!email || !password) return;
        setIsLoading(true);
        const authResponse = await JourneyApiClient.loginWithPassword(email, password);
        setIsLoading(false);
        if (authResponse.token) {
            JourneyApiClient.setAuthCredentials(authResponse.token);
            window.location.replace(`/?token=${authResponse.token}`);
        } else {
            setError('Password incorrect');
        }
    }

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const trackLoginSuccessfulEvent = async () => {
        await AnalyticsService.trackUserAction("login_success", location.pathname || null);
    }

    const trackLoginFailEvent = async () => {
        await AnalyticsService.trackUserAction("login_failure", location.pathname || null);
    }

    const submitAgain = async () => {
        setIsSubmitted(false);
        setIsCodeValid(true);
        setError(null);
        setCode("");
    }

    function handleEmailInputKeypress(e: React.KeyboardEvent) {
        if (e.key !== 'Enter') return;

        if (email && !showPassword) {
            loginWithSSOOrMagicLink();
        } else if (email && password) {
            loginWithPassword();
        }
    }

    const codeInputProps = {
        className: "code-input",
        inputStyle: {
            fontFamily: 'monospace',
            margin: '4px',
            flexGrow: "1",
            textAlign: 'center' as const,
            borderRadius: '4px',
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid #CFCFCF'
        },
        inputStyleInvalid: {
            fontFamily: 'monospace',
            margin: '4px',
            flexGrow: "1",
            textAlign: 'center' as const,
            borderRadius: '4px',
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid red'
        }
    }

    return (
        <IonContent className={"login-component paper-container"} >
            <div className={`login-page-content ${isLoading ? "blur-content" : "login-page-content"} ${isMobileApp ? "mobile" : ""}`}>
                <div className="logo-container">
                    <img src={Logo} className="logo-image" alt="Journey Logo" />
                </div>

                {error && !isSubmitted && (
                    <IonText color="danger" className="error-text">
                        {t(error)}
                    </IonText>
                )}

                {!isSubmitted && (
                    <IonItem className={error ? "email-input-error" : "email-input"}>
                        <IonInput className={"button-medium"} ref={inputElement} placeholder={t("Enter your email") ?? ""} type="email" autocomplete="email" onKeyPress={handleEmailInputKeypress} inputmode="email" value={email} onIonChange={e => setEmail(e.detail.value!)} pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$" />
                    </IonItem>
                )}

                {showPassword && <IonItem className={"password-input"}>
                    <IonInput placeholder={t("Password") ?? ""} type="password" value={password} onKeyPress={handleEmailInputKeypress} onIonChange={e => setPassword(e.detail.value!)} />
                </IonItem>}

                {isSubmitted && (
                    <>
                        <div className="code-sent header-6-variant">
                            {t("Your code has been sent!")}
                        </div>

                        <ReactCodeInput
                            type="number"
                            isValid={isCodeValid}
                            fields={6}
                            onChange={setCode}
                            value={code}
                            name={t("6 Digit Code")}
                            inputMode={"numeric"}
                            {...codeInputProps}
                        />

                        {error && (
                            <IonText color="danger" className="error-text">
                                {t(error)}
                            </IonText>
                        )}

                        <div className="code-sent-subtitle caption">
                            {t("Didn't get a code? Double check your email or")} <span className="submit-again" onClick={submitAgain}>{t("submit again.")}</span>
                        </div>
                    </>
                )}

                <IonButton className='button-medium-variant login-button' disabled={isLoading || (isSubmitted && code.length < 6)} onClick={handleLogin}>
                    {t("Log in")}
                </IonButton>

                {/* <a className='register-link' href='/register'>{t("I don't have access to my company email")}</a> */}
            </div>

            {isLoading && (
                <div className="loading-spinner">
                    <div><IonSpinner name="circles" /></div>
                </div>
            )}
        </IonContent>
    )
}
