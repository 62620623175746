import { IonRouterOutlet } from "@ionic/react";
import React, {useContext} from "react";
import {Route, Redirect} from "react-router-dom";
import {LoginPage} from "../../pages/LoginPage/LoginPage";
import {VideoListingPage} from "../../pages/VideoListingPage/VideoListingPage";
import {RegisterPage} from "../../pages/RegisterPage/RegisterPage";
import {DAILY_JOURNEY_PATH, REGISTER_PATH, REGISTER_COMPANY_SELECTOR_PATH, PUBLIC_WATCH_PATH, SSO_LOGIN_PATH, LANDING_PAGE_PATH} from "../../utils/utils";
import { DailyJourneyPage } from "../../pages/DailyJourneyPage/DailyJourneyPage";
import {PublicVideoPage} from "../../pages/PublicVideoPage/PublicVideoPage";
import {GuinessRecordChallenge} from "../../pages/GuinessRecordChallenge/GuinessRecordChallenge";
import {SsoLoginPage} from "../../pages/SsoLoginPage/SsoLoginPage";
import {LandingPage} from "../../pages/LandingPage/LandingPage";
import {ApplicationContext} from "../../misc/ApplicationContext";
import { RegisterCompanySelectorPage } from "../../pages/RegisterPage/RegisterCompanySelectorPage";
import { TypeformRegisterPage } from "../../pages/RegisterPage/TypeformRegisterPage";


export const LoggedOutNavigation: React.FC = () => {

    const {isMobileApp, isMobileMenu} = useContext(ApplicationContext);

    return(
        <IonRouterOutlet id="main" animated={!!isMobileApp}>
          <Route path="/login" component={LoginPage} exact={true}/>
          <Route path={REGISTER_PATH} component={TypeformRegisterPage} exact={true}/>
          {/* <Route path={REGISTER_COMPANY_SELECTOR_PATH} component={RegisterCompanySelectorPage} exact={true}/> */}
          <Route path={PUBLIC_WATCH_PATH} component={PublicVideoPage} exact={true}/>
          <Route path={`${DAILY_JOURNEY_PATH}/:id?`} component={DailyJourneyPage} exact={true} />
          <Route path="/" component={isMobileMenu ? LoginPage : VideoListingPage} exact={true}/>
          <Route path={SSO_LOGIN_PATH} component={SsoLoginPage} exact={true}/>
          <Route path={LANDING_PAGE_PATH} component={LandingPage} exact={true}/>
          <Route>
              <Redirect to={"/?login=true"} exact/>
          </Route>
        </IonRouterOutlet>
    );
};
