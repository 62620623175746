import * as React from "react";
import {useContext} from "react";
import "./ProfileMenuList.scss";
import {
    IonButton,
    IonIcon,
    IonItem,
    IonList
} from "@ionic/react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import Check from "../../assets/images/check.svg";
import CheckmarkGreen from "../../assets/images/checkmark-green.svg";
import Appstore from "../../assets/images/appstore-image.png";
import Googlestore from "../../assets/images/google-play-image.png";
import SlackLogo from "../../assets/images/slack-logo.png";
import ZoomLogo from "../../assets/images/zoom-logo.png";
import TeamsLogo from "../../assets/images/ms-teams-logo.png";
import AppleEmailLogo from "../../assets/images/email-apple-logo.png";
import GoogleEmailLogo from "../../assets/images/email-google-logo.png";
import OutlookEmailLogo from "../../assets/images/email-outlook-logo.png";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import AnalyticsService from "../../misc/AnalyticsService";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { Feature } from "../Feature/Feature";
import { openOutline } from 'ionicons/icons';

interface Props {
    handleMenuClose?: () => void;
    hideProgressBar: boolean;
}

function noop() {}

export const ProfileMenuList: React.FC<Props> = (props: Props) => {
    
    const {
        openMindfulBreakModal,
        openDownloadMobileAppModal,
        currentUser,
        setUserFlags,
        isMobileWidth,
        profileStepsCompleted,
        totalProfileSteps,
        isMobileApp,
        handleUserError
    } = useContext(ApplicationContext);
    const profileCompleted = profileStepsCompleted === totalProfileSteps;
    const location = useLocation();
    const { t } = useTranslation();
    const typeformUrl = "https://journey-live.typeform.com/testimonial";

    async function handleEmailButtonClick(emailClientClicked: string){
        await JourneyApiClient.saveUserCalendarEmailService(emailClientClicked);
        handleMindfulBreakOpen();
    }

    async function handleMindfulBreakOpen() {
        openMindfulBreakModal();
        await AnalyticsService.trackUserAction("profile_menu_mindful_break_opened", location.pathname);
    }

    async function handleDownloadMobileAppOpen() {
        if(isMobileApp) return;
        openDownloadMobileAppModal();
        await AnalyticsService.trackUserAction("profile_menu_download_mobile_opened", location.pathname);
    }

    async function handleDownloadZoomAppOpen() {
        if(currentUser?.company.type === "trial"){
            handleUserError(t("This feature is not available for Trial users."), " ");
            return;
        } 
        await JourneyApiClient.setUserHasZoomApp();
        setUserFlags("hasZoomAppInstalled");
        window.open('https://api.journey.live/zoomapp/install', '_blank', 'noreferrer');
        await AnalyticsService.trackUserAction("profile_menu_download_zoom_opened", location.pathname);
    }

    async function handleDownloadSlackAppOpen() {
        if(currentUser?.company.type === "trial"){
            handleUserError(t("This feature is not available for Trial users."), " ");
            return;
        } 
        //await JourneyApiClient.setUserHasSlackApp();
        // setUserFlags("hasSlackAppInstalled");
        window.open('https://api.journey.live/slackapp/install', '_blank', 'noreferrer');
        await AnalyticsService.trackUserAction("profile_menu_download_slack_opened", location.pathname);
    }

    async function handleDownloadTeamsAppOpen() {
        if(currentUser?.company.type === "trial"){
            handleUserError(t("This feature is not available for Trial users."), " ");
            return;
        } 
        await JourneyApiClient.setUserHasTeamsApp();
        setUserFlags("hasTeamsAppInstalled");
        // window.open('https://api.journey.live/zoomapp/install', '_blank', 'noreferrer');
        await AnalyticsService.trackUserAction("profile_menu_download_teams_opened", location.pathname);
    }

    async function handleShareYourExperience() {
        await JourneyApiClient.setUserHasSharedExperience();
        setUserFlags("hasSharedExperience");
        window.open(typeformUrl, '_blank', 'noreferrer');
        await AnalyticsService.trackUserAction("profile_menu_share_experience_opened", location.pathname);
    }

    return (
        <IonList className={"profile-menu-list"}>
            <IonItem className={"profile-menu-item"}>
                <div className={"subtitle1-variant title"}>
                    {profileCompleted ? t("Profile Completed") : t("Complete Your Profile")}
                    <div className={"profile-progress-bar-container"}>
                        {profileCompleted ? <img src={CheckmarkGreen} alt="Completed profile" className={"completed-profile-checkmark"}/> :
                        <CircularProgressbar
                            value={profileStepsCompleted}
                            maxValue={totalProfileSteps}
                            styles={buildStyles({
                                textColor: "#4EDED6",
                                pathColor: "#4EDED6",
                                textSize: "35px",
                                trailColor: "#F7F2ED"})}
                        />}
                    </div>
                </div>
            </IonItem>

            {!isMobileApp &&
            <IonItem className={"profile-menu-item"} detail={false} onClick={isMobileWidth ? handleDownloadMobileAppOpen : noop}>
                <div className={`box-number ${currentUser?.hasMobileAppInstalled ? 'complete' : ''}`}>
                    {currentUser?.hasMobileAppInstalled ? <img src={Check} alt="Check"/> : ''}
                </div>
                <div className="text-container">
                    <div onClick={handleDownloadMobileAppOpen} className={`${isMobileWidth ? "button-medium" : "body-large"} label`}>
                        {t("Download our mobile app")}
                    </div>
                    <div>
                        <img onClick={handleDownloadMobileAppOpen} className="profile-menu-logo apple-logo" src={Appstore} alt={"App Store Logo"} />
                        <img onClick={handleDownloadMobileAppOpen} className="profile-menu-logo google-logo" src={Googlestore} alt={"Google Play Store Logo"}/>
                    </div>
                </div>
            </IonItem>
            }

            <Feature feature="slack-app">
                <IonItem className={"profile-menu-item"} detail={false} onClick={isMobileWidth ? handleDownloadSlackAppOpen : noop}>
                    <div className={`box-number ${currentUser?.hasZoomAppInstalled ? 'complete' : ''}`}>
                        {currentUser?.hasZoomAppInstalled ? <img src={Check} alt="Check"/> : ''}
                    </div>
                    <div className="text-container">
                        <div onClick={handleDownloadSlackAppOpen} className={`${isMobileWidth ? "button-medium" : "body-large"} label`}>
                            {t("Download our app on")}
                        </div>
                        <img onClick={handleDownloadSlackAppOpen} className="profile-menu-logo slack-logo" src={SlackLogo} alt={"Slack Logo"} />
                    </div>
                </IonItem>
            </Feature>

            <Feature feature="zoom-app">
                <IonItem className={"profile-menu-item"} detail={false} onClick={isMobileWidth ? handleDownloadZoomAppOpen : noop}>
                    <div className={`box-number ${currentUser?.hasZoomAppInstalled ? 'complete' : ''}`}>
                        {currentUser?.hasZoomAppInstalled ? <img src={Check} alt="Check"/> : ''}
                    </div>
                    <div className="text-container">
                        <div onClick={handleDownloadZoomAppOpen} className={`${isMobileWidth ? "button-medium" : "body-large"} label`}>
                            {t("Download our app on")}
                        </div>
                        <img onClick={handleDownloadZoomAppOpen} className="profile-menu-logo zoom-logo" src={ZoomLogo} alt={"Zoom Logo"} />
                    </div>
                </IonItem>
            </Feature>

            <Feature feature="teams-app">
                <IonItem className={"profile-menu-item"} detail={false} onClick={isMobileWidth ? handleDownloadTeamsAppOpen : noop}>
                    <div className={`box-number ${currentUser?.hasTeamsAppInstalled ? 'complete' : ''}`}>
                        {currentUser?.hasTeamsAppInstalled ? <img src={Check} alt="Check"/> : ''}
                    </div>
                    <div className="text-container">
                        <div onClick={handleDownloadTeamsAppOpen} className={`${isMobileWidth ? "button-medium" : "body-large"} label`}>
                            {t("Download our app on")}
                        </div>
                        <img onClick={handleDownloadTeamsAppOpen} className="profile-menu-logo teams-logo" src={TeamsLogo} alt={"Teams Logo"} />
                    </div>
                </IonItem>
            </Feature>


            <IonItem className={"profile-menu-item"} detail={false} >
                <div className={`box-number ${currentUser?.hasAddedMindfulBreak ? 'complete' : ''}`}>
                    {currentUser?.hasAddedMindfulBreak ? <img src={Check} alt="Check"/> : ''}
                </div>
                <div className="text-container">
                    <div onClick={handleMindfulBreakOpen} className={`${isMobileWidth ? "button-medium" : "body-large"} label`}>
                        {t("Add a Mindful Break to your calendar")}
                    </div>
                    <div>
                        {(currentUser?.calendarEmailService === null || currentUser?.calendarEmailService === "Apple") &&
                        <IonButton className="email-button" onClick={() => handleEmailButtonClick("Apple")}>
                            <img className="profile-menu-logo emails-logo" src={AppleEmailLogo} alt={"Emails Logo"} />
                        </IonButton>}
                        {(currentUser?.calendarEmailService === null || currentUser?.calendarEmailService === "Google") &&
                        <IonButton className="email-button" onClick={() => handleEmailButtonClick("Google")}>
                            <img className="profile-menu-logo emails-logo" src={GoogleEmailLogo} alt={"Emails Logo"} />
                        </IonButton>}
                        {(currentUser?.calendarEmailService === null || currentUser?.calendarEmailService === "Outlook") &&
                        <IonButton className="email-button" onClick={() => handleEmailButtonClick("Outlook")}>
                            <img className="profile-menu-logo emails-logo" src={OutlookEmailLogo} alt={"Emails Logo"} />
                        </IonButton>}
                    </div>
                </div>
            </IonItem>
            <Feature feature="testimonials">
                <IonItem className={"profile-menu-item"} detail={false} onClick={isMobileWidth ? handleShareYourExperience : noop}>
                    <div className={`box-number ${currentUser?.hasSharedExperience ? 'complete' : ''}`}>
                        {currentUser?.hasSharedExperience ? <img src={Check} alt="Check"/> : ''}
                    </div>
                    <div className="text-container">
                        <div onClick={handleShareYourExperience} className={`${isMobileWidth ? "button-medium" : "body-large"} label`}>
                            {t("Share Your Experience")}
                            <IonIcon icon={openOutline} className={`open-icon`} />
                        </div>
                    </div>
                </IonItem>
            </Feature>
        </IonList>
    )
}
